/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, Fragment, useEffect, FormEvent } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import getEnv from '@/utils/getEnv';
import { getCurrentHub } from '@sentry/nextjs';
import useAmpComponent from '@/hooks/useAmpComponent';
import CTLogo from '../../assets/svg/ctlogo.svg';
import * as S from './styles';
import NewsTab from './NavbarContent/NewsTab';
import VideosTab from './NavbarContent/VideosTab';
import PodcastTab from './NavbarContent/PodcastTab';
import ProductsTab from './NavbarContent/ProductsTab';
import MoreCanaltech from './NavbarContent/MoreCanaltechTab';
import OfferTab from './NavbarContent/OfferTab';
import classNames from '@/utils/className';

export type NavBarContent = {
  top: {
    Notícias: {
      tipo: string;
      link: string;
      prefixo: string;
      svg: string;
      links: unknown;
      badge: null | boolean;
      classe: string | null;
      id: string;
    };
    Vídeos: {
      tipo: string;
      link: string;
      prefixo: string;
      svg: string;
      links: unknown;
      badge: null | boolean;
      classe: string | null;
      id: string;
    };
    Podcasts: {
      tipo: string;
      link: string;
      prefixo: string;
      svg: string;
      links: unknown;
      badge: null | boolean;
      classe: string | null;
      id: string;
    };
    Produtos: {
      link: string;
      svg: string;
      links: unknown;
      badge: null | boolean;
      classe: string | null;
      id: string;
    };
    'Mais Canaltech': {
      svg: string;
      links: unknown;
      badge: null | boolean;
      classe?: string | null;
      id: string;
    };
    'Canaltech Ofertas': {
      svg: string;
      links: unknown;
      badge: null | boolean;
      classe?: string | null;
      id: string;
    };
    'Prêmio Canaltech': {
      link: string;
      badge: null | boolean;
      classe?: string | null;
      id: string;
      svg: string;
    };
  };
  bottom: {
    Busca: {
      link: string;
      svg: string;
      badge: null | boolean;
      toggle: string;
    };
  };
};

export interface NavbarProps {
  navBarContent: NavBarContent;
  headingTag?: 'h1' | 'h2';
  mandatorySearch?: boolean;
}

export function Navbar({ navBarContent, headingTag = 'h2', mandatorySearch = false }: NavbarProps): JSX.Element {
  const { Icon } = useAmpComponent();
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const navbarSpan = currentSpan && currentSpan.startChild({ op: 'navbar' });

  const [isOpen, setOpen] = useState(false);
  const [optionName, setOption] = useState('');
  const [searchContent, setSearchContent] = useState('');
  const router = useRouter();
  const titleCt = 'Canaltech - Notícias de Tecnologia, Ciência e Entretenimento';
  const Heading = headingTag;

  function HandleMenuAction(option: string, itemMenu = null) {
    if (itemMenu && option !== 'Prêmio Canaltech' && option !== optionName) {
      if (option !== 'Busca') setOpen(true);
      setOption(option);
      return;
    }

    if (itemMenu && option === 'Prêmio Canaltech') {
      setOpen(false);
      router.push(itemMenu.link);
      return;
    }

    if (isOpen) {
      setOpen(false);
      setOption('');
    }
  }

  function HandleSearchSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const isValid = () => searchContent.trim().length > 0 && searchContent !== router.query.q;
    if (isValid()) window.location.href = `/busca/?q=${searchContent}`;
    setOpen(false);
    setOption('');
  }

  return (
    <S.NavBarMain>
      <S.Cabecalho>
        <div>
          <button
            type="button"
            aria-label="Menu"
            onClick={() => {
              if (isOpen) {
                setOption('Notícias');
                setOpen(false);
                return;
              }

              setOption('Notícias');
              setOpen(true);
            }}>
            <Icon icon={!isOpen ? 'mdi:menu' : 'mdi:close'} width="24" height="24" color="rgb(255, 255, 255)" />
          </button>
          <Heading id="heading-title-logo" className="invisible hidden">
            {titleCt}
          </Heading>
          <Link href={`${getEnv('URL')}/`} passHref>
            <a title="Página Principal do Canaltech">
              <CTLogo />
            </a>
          </Link>
        </div>
      </S.Cabecalho>
      <S.NavBarContainer menuStatus={isOpen}>
        <ul>
          {Object.entries(navBarContent.top).map((link, index) => (
            <Fragment key={index}>
              <S.MenuAction
                onClick={() => {
                  HandleMenuAction(link[0], link[1]);
                }}
                isActive={optionName === link[0]}
                dangerouslySetInnerHTML={{
                  __html: `
                ${link[1].svg}
                ${link[1].badge ? '<span/>' : ''}
                `,
                }}
              />
            </Fragment>
          ))}
          {Object.entries(navBarContent.bottom).map((link, index) => (
            <Fragment key={index}>
              <S.MenuAction
                onClick={() => {
                  HandleMenuAction(link[0], link[1]);
                }}
                isActive={optionName === index[0]}
                dangerouslySetInnerHTML={{
                  __html: `
                ${link[1].svg}
                ${link[1].badge ? '<span/>' : ''}
                `,
                }}
              />
            </Fragment>
          ))}
        </ul>
      </S.NavBarContainer>
      {isOpen && (
        <div
          className="fixed left-0 right-0 top-0 z-[1000] block h-screen bg-black/5 transition-all duration-300 ease-in-out"
          onClick={() => {
            setOpen(false);
            setOption('');
          }}
        />
      )}
      <S.MenuSuspenso menuStatus={isOpen}>
        <NewsTab newsTabData={navBarContent.top.Notícias} isActive={optionName === 'Notícias'} setOpen={x => setOpen(x)} setOption={x => setOption(x)} />
        <VideosTab newsTabVideos={navBarContent.top.Vídeos} isActive={optionName === 'Vídeos'} setOpen={x => setOpen(x)} setOption={x => setOption(x)} />
        <PodcastTab newsTabPodcast={navBarContent.top.Podcasts} isActive={optionName === 'Podcasts'} setOpen={x => setOpen(x)} setOption={x => setOption(x)} />
        <ProductsTab
          newsTabProducts={navBarContent.top.Produtos}
          isActive={optionName === 'Produtos'}
          setOpen={x => setOpen(x)}
          setOption={x => setOption(x)}
        />
        <MoreCanaltech
          newsTabMore={navBarContent.top['Mais Canaltech']}
          isActive={optionName === 'Mais Canaltech'}
          setOpen={x => setOpen(x)}
          setOption={x => setOption(x)}
        />
        <OfferTab
          newsTabOffer={navBarContent.top['Canaltech Ofertas']}
          isActive={optionName === 'Canaltech Ofertas'}
          setOpen={x => setOpen(x)}
          setOption={x => setOption(x)}
        />
        <div
          className={classNames(
            'fixed left-0 right-0 top-0 z-[1000] h-screen flex-col justify-center bg-mglGray900 transition-all duration-300 ease-in-out [&.showMenu]:flex [&:not(.showMenu)]:hidden',
            (optionName === 'Busca' || mandatorySearch) && 'showMenu'
          )}>
          {!mandatorySearch && (
            <button className="absolute bottom-auto right-5 top-5 cursor-pointer border-none bg-mglGray900 text-mglGray50">
              <Icon
                icon="mdi:close"
                width="32"
                height="32"
                onClick={() => {
                  setOption('');
                  setOpen(false);
                }}
              />
            </button>
          )}
          <form className="relative ml-[20%] mr-[20%] flex justify-center px-4 py-0 text-mglGray50" onSubmit={event => HandleSearchSubmit(event)}>
            <input
              id="Buscar"
              className="min-h-[50px] w-full border-0 border-b border-solid border-b-mglGray50 bg-mglGray900 text-4xl font-light text-mglGray50"
              type="text"
              placeholder="Buscar"
              onChange={e => setSearchContent(e.target.value)}
            />
            <button className="border-0 border-b border-solid border-mglGray50 bg-mglGray900 text-mglGray50" aria-label="Fechar busca">
              <Icon icon="mdi:magnify" width="32" height="32" />
            </button>
          </form>
        </div>
      </S.MenuSuspenso>
      {navbarSpan && navbarSpan.finish()}
    </S.NavBarMain>
  );
}
